import { url_webservice,url_server_moodle } from '../data/global'


const getCoursesByUser = (userId) => 
    fetch(url_webservice+"",
        {
            method: 'POST',
			headers: { 
				'Content-Type': 'application/x-www-form-urlencoded'
			},
            body: new URLSearchParams(
				{
					"wstoken": localStorage.getItem("tokenMoodle"),
					"wsfunction": "core_enrol_get_users_courses",
                    "moodlewsrestformat": "json",
					"userid": userId
				})				
        }
    )
    .then((response) => {
        return response.json()
    })

const getParticipantsInACourse = (courseId) => 
    fetch(url_webservice+"",
        {
            method: 'POST',
			headers: { 
				'Content-Type': 'application/x-www-form-urlencoded'
			},
            body: new URLSearchParams(
				{
					"wstoken": localStorage.getItem("tokenMoodle"),
					"wsfunction": "core_enrol_get_enrolled_users",
                    "moodlewsrestformat": "json",
					"courseid": courseId
				})				
        }
    )
    .then((response) => {
        return response.json()
    })

const getCourses = (page) => 
    fetch(url_server_moodle + 'courses/'+page,
        {
            method: 'GET',
            headers: { 
                'Authorization': "Bearer "+localStorage.getItem('token'), 
                
            }				
        }
    )

const uploadMd = (course, data) => 
    fetch(url_server_moodle+'course_mds/upload-course_md/'+course,
        {
            method: 'POST',
            headers: { 
                'Authorization': "Bearer "+localStorage.getItem('token'),
            },
            body: data,
            redirect: 'follow'		
        }
    )  
		
const getCourseMds = (course) => 
    fetch(url_server_moodle + 'course_mds/course/'+course,
        {
            method: 'GET',		
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+localStorage.getItem('token')   
            }		
        }
    )

export {
    getCoursesByUser,
    getParticipantsInACourse,
    getCourses,
    uploadMd,
    getCourseMds
}