import Moment from 'react-moment';

export const RowCourse_md = ({course_md}) => {
    return (
        <tr >
            <td>
                {course_md.file_md}    
            </td>
            <td>{<Moment format="HH:mm:ss DD-MM-YYYY" unix>
                    {course_md.created_at}
            </Moment>}</td>				
        </tr>
    );
}
export default RowCourse_md;