import React, {Component} from 'react'
import { Table, Form, Button,Container, Spinner } from 'react-bootstrap';
import {
	BrowserRouter as Router,
	Redirect
} from 'react-router-dom'

import { getCoursesByUser , getParticipantsInACourse } from '../../../services/Course'

import RowMyCourses from './components/RowMyCourses';


export default class MyCourses extends Component {

	constructor(...props){
		super(...props)
		this.state = { 
			redirectRoute: 0,
			isLoading: false,
            courses: []
		}
	}

	async componentDidMount(){		

		this.setState({ isLoading: true })
		var userId = localStorage.getItem("user")
		const listCourse = await getCoursesByUser(userId)
        .then((res) => {
				return res;
		})
		.catch(function(error) {
			this.setState({ isLoading: false});
			console.log('Hubo un problema con la petición Fetch:');
		});

		for(var i=0; i<listCourse.length; i++){
			const list = await getParticipantsInACourse(listCourse[i].id)
			.then((res) => {
				return res;	
			})
			.catch(function(error) {
				this.setState({ isLoading: false})
				console.log('Hubo un problema con la petición Fetch:' + error.message);
			});
			for(var j=0; j<list.length; j++){
				
				if(list[j].id == userId){
					listCourse[i].userroles = list[j].roles;
				}
			}
		}
		

		this.setState({ isLoading: false })
		this.setState({
			courses: this.state.courses.concat(listCourse)
		
		})		
	}


	goQuizStudent = (course,isTeacher, isStudent) =>{
		localStorage.removeItem("courseId");
		localStorage.setItem("courseId",course.id);
		localStorage.removeItem("courseName");
		localStorage.setItem("courseName",course.fullname);
		localStorage.removeItem("isTeacher");
		localStorage.setItem("isTeacher",isTeacher);
		localStorage.removeItem("isStudent");
		localStorage.setItem("isStudent",isStudent);
		this.setState({redirectRoute: 2})
	}

	goListQuizzes(course){
		localStorage.removeItem("courseId");
		localStorage.setItem("courseId",course.id);
		localStorage.removeItem("courseName");
		localStorage.setItem("courseName",course.fullname);

		this.setState({redirectRoute: 3})
	}

	render(){	
		const {isLoading, redirectRoute, courses } = this.state
		var listCourses = courses.map(course =>{
			return (
                <RowMyCourses key={course.id} course={course} goQuizStudent={this.goQuizStudent}/>
            )	
		})			


		switch(redirectRoute){
			case 0:
				return(
				isLoading===true
					?<div style={{
						position: 'absolute', left: '50%', top: '50%',
						transform: 'translate(-50%, -50%)'
					}}>
						<Spinner animation="border" variant="info" />
					</div>
					:<Container>
						<h1 className="header">Mis cursos</h1>
						<Table responsive striped bordered hover>
							<thead>
								<tr>
									<th>Nombre del curso</th>
									<th>Roles</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{listCourses}
							</tbody>
						</Table>						
					</Container>
								
				)
			case 1:
				return(
					<Redirect to="/my-course" />
				)
			case 2:
				return(
					<Redirect to="/student-my-quizzes" />
				)
			case 3:
				return(
					<Redirect to="/list-quizzes" />
				)
		}
	}
}