import { FaTimes,FaCheck, } from 'react-icons/fa';
import { IconContext } from "react-icons";

export const RowStudent = ({question, index}) => {
    var resAnswer = "";
    var resCorrect = <FaTimes />;
    var resCorrectColor = "red";
    if(!question.exam_answers){
        resAnswer = "Pregunta no respondida";
        resCorrect ="";
    }else{
        
        if(question.exam_answers.choice == question.exam_answers.correct_choice){//si respuesta escogida es igual a respuesta correcta
            resCorrect = <FaCheck />;
            resCorrectColor = "green";
        }
    }
    return (
        <tr >
            <td><span ><strong>{"Pregunta "+index+" "+resAnswer}</strong></span>
            <IconContext.Provider value={{ color: resCorrectColor }}>
                {resCorrect}
            </IconContext.Provider>
            <div dangerouslySetInnerHTML={{__html: question.questiontext+"<br>"}}>
            </div>
            {                       
                question.choices.map( choice =>{  
                    var colorCorrect = "none";
                    
                    if(question.exam_answers){
                        if(question.exam_answers.choice == choice.id){//si respuesta escogida es igual a respuesta correcta
                            colorCorrect = "red";									
                        }								
                        if(question.exam_answers.correct_choice == choice.id){
                            colorCorrect = "green";
                        }
                    }
                    
                    return(
                        <tr style={{ color: colorCorrect}}  ><div dangerouslySetInnerHTML={{__html: choice.answer}}></div></tr>
                    )								
                })							
            }    
            </td>
        </tr>
    )
}
export default RowStudent;