import React, {Component} from 'react'
import { Table, Form, Button,Container,Badge,Row,Col,Jumbotron, Spinner } from 'react-bootstrap';
import {
	BrowserRouter as Router,
	Redirect
} from 'react-router-dom'

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';

import { FaTimes,FaCheck, FaArrowLeft, FaFilePdf } from 'react-icons/fa';

import { IconContext } from "react-icons";



import { getExamAnswerByUser, getAllExamAnswerBySession  } from '../../../services/Quiz'
import { getParticipantsInACourse } from '../../../services/Course'
import RowTeacher from './components/RowTeacher'

import {buildArrayPdfForReviewTeacher} from "./pdf/Documents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs


export default class ReviewTeacher extends Component {

	constructor(...props){
		super(...props)
		this.state = { 
			redirectRoute: 0,
			isLoading: false,
            users: [],
			courseName: "",
			quizName: "",
            percent: "",
			session: "",
			correctAnswers: "",
			total_items: 0,
			quizId: 0,
			sessionId: 0,
			message:""
		}	
		this.getAllExamAnswer = this.getAllExamAnswer.bind(this)
		this.goReview = this.goReview.bind(this)
	
	}
	

	goReview(user,e){
		localStorage.removeItem("ReviewStudent_quizId");
		localStorage.setItem("ReviewStudent_quizId", this.state.quizId);
		localStorage.removeItem("ReviewStudent_sessionId");
		localStorage.setItem("ReviewStudent_sessionId", this.state.sessionId);
		localStorage.removeItem("ReviewStudent_courseName");
		localStorage.setItem("ReviewStudent_courseName", this.state.courseName);
		localStorage.removeItem("ReviewStudent_quizName");
		localStorage.setItem("ReviewStudent_quizName", this.state.quizName);
		localStorage.removeItem("ReviewStudent_fullName");
		localStorage.setItem("ReviewStudent_fullName", user.fullname);
		localStorage.removeItem("ReviewStudent_user");
		localStorage.setItem("ReviewStudent_user", user.userId);
		this.setState({redirectRoute: 1})		
	}

	

	getAllExamAnswer(users,names, sessionId,quizId){
		getAllExamAnswerBySession(localStorage.getItem('courseId'), users, names, sessionId, quizId)
		.then((res) => {
			console.log("res)");
			console.log(res);
			if(res.status){
				//if(res.messa)
				switch(res.status){
					case 404:
						this.setState({ isLoading: false});
						this.setState({ message: "Todavia nadie ha respondido el examen"});
						//listNotes = new Array();
						//localStorage.removeItem('Notes');
						break;
					case 401:
						this.props.logoutCallback();
						//this.setState({ redirectRoute: true })					
						break;
				}
			}else{
				this.setState({ isLoading: false, courseName: localStorage.getItem('courseName'),quizName: localStorage.getItem('teach_quizName'),
				sessionId: sessionId, quizId: quizId })
				this.setState({
					users: this.state.users.concat(res.userList)
				})
			}
		})
		.catch(function(error) {
			this.setState({ isLoading: false})
			console.log('Hubo un problema con la petición Fetch:' + error.message);
		});
	}

	componentDidMount(){	
        //console.log("1111111111111111111")	
		this.setState({ isLoading: true});
		var isStudent = JSON.parse(localStorage.getItem('teach_isStudent'))
		var user = localStorage.getItem('user')
		
		getParticipantsInACourse(localStorage.getItem('courseId'))
		.then((res) => {
			if(res.status){
				//if(res.messa)
				switch(res.status){
					case 404:
						this.setState({ isLoading: false});
						break;
					case 401:
						this.props.logoutCallback();
						//this.setState({ redirectRoute: true })					
						break;
				}
			}else{
				//this.setState({ isLoading: false, name: localStorage.getItem('rev_name'),percent: 
				//	res.percent,session: res.session, correctAnswers: res.correctAnswers, total_items: res.total_items  })
				//this.setState({
				//	questions: this.state.questions.concat(res.questions)
				//})
				var usersIds="";
				var usersNames="";	
				for(var i=0;i<res.length;i++){
					if(!isStudent && user == res[i].id){
						console.log("usersNames--------111");
					}else{
						usersIds += res[i].id+"|";
						usersNames += res[i].fullname+"|";
						//console.log("-----------------------------");
						//console.log(res[i]);
					}
					//console.log(users);
				}
				console.log("usersNames--------");
				console.log(usersNames);
				console.log(usersIds);
				
				this.getAllExamAnswer(usersIds.substring( 0, usersIds.length-1),usersNames.substring( 0, usersNames.length-1), localStorage.getItem('teach_sessionId'),localStorage.getItem('teach_quizId'))
			}
		})
		.catch(function(error) {
			this.setState({ isLoading: false})
			console.log('Hubo un problema con la petición Fetch:' + error.message);
		});
	}


	createPdf = (users, quizName, courseName) => {
		

		const pdfGenerator = pdfMake.createPdf(buildArrayPdfForReviewTeacher(users, quizName, courseName));
		//pdfGenerator.getBlob((blob) => {
		//  const url = URL.createObjectURL(blob);
		//  setUrl(url)
		//})
		pdfGenerator.download()
	}

	render(){	
		const {isLoading, redirectRoute, users, quizName, courseName, message } = this.state
		var listUsers = users.map(user =>{
			return (
               <RowTeacher key={user.id} user={user} goReview={this.goReview}/> 
            )	
		})			


		switch(redirectRoute){
			case 0:
				return(
					isLoading===true
					?<div style={{
						position: 'absolute', left: '50%', top: '50%',
						transform: 'translate(-50%, -50%)'
					}}>
						<Spinner animation="border" variant="info" />
					</div>
					:
					<Container>
							<Row className="justify-content-md-center">
								<Col>
									<h1>Curso: {courseName}</h1>
									<h3>Nombre de examen: {quizName}</h3>
									<p>{message}</p>
									<Button variant="outline-info" size="lg" onClick={()=> this.setState({redirectRoute: 2} )}>
										<IconContext.Provider value={{ color: "info" }}>
											<FaArrowLeft   /> 
										</IconContext.Provider>										
									</Button>
									{' '}
									<Button variant="outline-danger" size="lg" onClick={()=> this.createPdf(users, quizName, courseName)}>
										<FaFilePdf   /> 										
									</Button>
								</Col>
								
							</Row>
							
							<Row>
								<Col>
									<Table responsive striped bordered hover>
										<thead>
											<tr>
												<th>Estudiante</th>
												<th>Nro Preguntas correctas</th>
												<th>Nro Preguntas incorrectas</th>
												<th>No respondidas</th>
												<th>Porcentaje</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{listUsers}
										</tbody>
									</Table>
								</Col>
								{/* <MyDocument /> */}
							</Row>                       						
						</Container>			
				)
			case 1:
				return(
					<Redirect to="/review-student" />
				)
			case 2:
				return(
					<Redirect to="/student-my-quizzes" />
				)
		}
	}
}