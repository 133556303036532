import React, { Component } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Link,
	Redirect,
	Switch
} from 'react-router-dom'

import Error404 from './pages/Error404'
import Login from './pages/public/Login'

import { Navbar,Nav } from 'react-bootstrap';
import MyCourses from './pages/private/mycourses/MyCourses'
import MyQuizStudent from './pages/private/Quiz/Student'
import Review from './pages/private/Quiz/Review'
import Uploader from './pages/private/Uploader/Uploader'
import ReviewTeacher from './pages/private/Quiz/ReviewTeacher'
import ReviewStudent from './pages/private/Quiz/ReviewStudent'
import Courses from './pages/private/Courses/Courses'
import Course_mds from './pages/private/Course_mds/Course_mds'
import UploadMd from './pages/private/Courses/UploadMd'

import ListQuizzes from './pages/private/Quiz/ListQuizzes'

const PrivateRoute = ({component: Component, authed, rest})=>(
	<Route 
		{...rest}
		render = {
			props => authed === true
			? <Component {...props} />
			: <Redirect to={{pathname: '/login', state:{ from: props.location}}} />			
		}
	/>
)

const PublicRoute = ({ component: Component, authed, rest }) => (
	<Route 
		{...rest}
		render={
			props => authed === false
				? <Component {...props} />
				: <Redirect to = '/my-courses' />
		}	
	/>
)

class App extends Component {
	
	constructor(...props) {
		super(...props)
		this.state = {
			authed: false,
			loading: false,
			image: ''
		}
		
	}

	
	
	
	componentDidMount(){		
        const token = localStorage.getItem('token');
		console.log("token")
		console.log(token)
		if (token !== null) {
			this.setState({
				authed: true,
				loading: false
			})
		}
		else
		{
			this.setState({
				loading: false
			})
		}
		
	
	}

	myFunction() { 
		this.props.history.push("/dashboard"); 
	  } 

	componentWillUnmount() {
		//this.removeListener()
	}


	loginSuccess = () => {
		this.setState({
			authed: true,
			loading: false
		})
		console.log("autorizado")
		console.log(this.state.authed)	
		//router.setPath('/nuevo');	
		
  	}

	logout = () => {
		localStorage.clear();
		this.setState({authed: false})
		//router.setPath('/nuevo');	
		
  	}

	render() {
		return this.state.loading === true
			? <h1>Cargando...</h1>
			: (
				<Router>
					<div>
                    <Navbar bg="dark" variant="dark">
                            <Navbar.Brand href="#/">{localStorage.getItem("fullname")}</Navbar.Brand>
                            <Nav className="mr-auto">
                            {
                                (this.state.authed)
                                ?
                                    <>
										<Link  className="nav-link"  to="/my-courses" >Mis cursos</Link>
										<Link  className="nav-link"  to="/courses" >Cursos</Link>
										<Link  className="nav-link" 
													onClick={ ()=>{
														this.logout()
													}}>Salir</Link>
                                    </>
                                :
                                    <>
										<Link  className="nav-link"  to="/login" >Login</Link>                                    
                                    </>
                            }
                            </Nav>
                            
                        </Navbar>
                        
						<main className="Main">
							<Switch>
								<PrivateRoute authed={this.state.authed} path='/student-my-quizzes' component={ ()=><MyQuizStudent logoutCallback = { this.logout } />} />
								<PrivateRoute authed={this.state.authed} path='/list-quizzes' component={ ()=><ListQuizzes logoutCallback = { this.logout } />} />
								<PrivateRoute authed={this.state.authed} path='/review' component={()=><Review logoutCallback = { this.logout } />} />
								<PrivateRoute authed={this.state.authed} path='/review-teacher' component={()=><ReviewTeacher logoutCallback = { this.logout } />} />
								<PrivateRoute authed={this.state.authed} path='/review-student' component={()=><ReviewStudent logoutCallback = { this.logout }/> } />
								<PrivateRoute authed={this.state.authed} path='/my-courses' component={()=><MyCourses logoutCallback = { this.logout }/>} />
								<PrivateRoute authed={this.state.authed} path='/courses' component={()=><Courses logoutCallback = { this.logout }/>} />
								<PrivateRoute authed={this.state.authed} path='/course_mds' component={()=><Course_mds logoutCallback = { this.logout }/>} />
								<PrivateRoute authed={this.state.authed} path='/uploadMd' component={()=><UploadMd logoutCallback = { this.logout }/>} />
								
								<PublicRoute  authed={this.state.authed} path='/login' component={()=><Login loginCallback = {this.loginSuccess} />} />																							
								<Route path='/' component={()=> <Redirect to='my-courses'/>} />
								<Route component={Error404} />
							</Switch>
						</main>
					</div>
				</Router>
			)
	}
}
export default App