import React, {Component} from 'react'

export default class Error extends Component {
	render(){
		return(
			<article className="Main-container">
				<h1>Pagina no encontrada</h1>
			</article>
		)
	}
}