import { Button} from 'react-bootstrap';
import { FaList } from 'react-icons/fa';

import Moment from 'react-moment';

export const RowCourse = ({course, goUploadMdC}) => {
    return (
        <tr >
            <td>
                {course.fullname}
            </td>
            <td>{<Moment format="HH:mm:ss DD-MM-YYYY" unix>
                    {course.timecreated}
            </Moment>}</td>
            <td>
                <Button variant="secondary" type="button" title={"Listar MDs"} onClick={()=>{goUploadMdC(course)}} >
                    <FaList /> 
                </Button>
            </td>						
        </tr>
    );
}
export default RowCourse;