import { url_server_moodle} from '../data/global'


const getQuizzes = (page) => 
    fetch(url_server_moodle + 'quizzes',
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+localStorage.getItem('token')   
            }				
        }
    )
    .then((response) => {
        return response.json()
    })


const getMyQuizzesByCourse = (courseid, page) => 
    fetch(url_server_moodle + 'quizzes/my-quizzes-by-course/'+courseid+'/'+page,
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+localStorage.getItem('token')   
            }				
        }
    )
    .then((response) => {
        return response.json()
    })    

const getExamAnswerByUser = (quizId, sessionId) => 
    fetch(url_server_moodle + 'exam_answers/exam/'+quizId+'/session/'+sessionId,
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+localStorage.getItem('token')   
            }				
        }
    )
    .then((response) => {
        return response.json()
    })

const getExamAnswerByTeacher = (quizId, sessionId, user) => 
    fetch(url_server_moodle + 'exam_answers/exam/'+quizId+'/session/'+sessionId+'/user/'+user,
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+localStorage.getItem('token')   
            }				
        }
    )
    .then((response) => {
        return response.json()
    })

const getAllExamAnswerBySession = (courseId, users, names, sessionId, quizId) => 
    fetch(url_server_moodle + 'exam_answers/course/'+courseId+'/users/'+users+'/names/'+names+'/session/'+sessionId+'/quiz/'+quizId,
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+localStorage.getItem('token')   
            }				
        }
    )
    .then((response) => {
        return response.json()
    })    
const getQuizzesByCourse = (courseId) => 
    fetch(url_server_moodle + 'quizzes/all-quizzes/'+courseId,
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+localStorage.getItem('token')   
            }				
        }
    )
    
const getQuiz = (quizId) => 
    fetch(url_server_moodle + 'quizzes/quiz/'+quizId,
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+localStorage.getItem('token')   
            }				
        }
    )
    .then((response) => {
        return response.json()
    })

export {
    getQuizzes,
    getMyQuizzesByCourse,
    getExamAnswerByUser,
    getExamAnswerByTeacher,
    getAllExamAnswerBySession,
    getQuizzesByCourse,
    getQuiz
}