function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
  
function formatDate(date) {
return (
    [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
    ].join('-') +
    ' ' +
    [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
    ].join(':')
);
}

function buildArrayPdfForReviewTeacher(users, quizName, courseName){
    var data = [];

    data.push([{ text: 'Estudiante', style: 'tableHeader' }, { text: 'Nro Preguntas correctas', style: 'tableHeader' },{ text: 'Nro Preguntas incorrectas', style: 'tableHeader' },{ text: 'No respondidas', style: 'tableHeader' }, {text:'Porcentaje', style: 'tableHeader' }]);
    users.map(user =>{
        data.push([user.fullname, user.correctAnswers, user.incorrectAnswers, user.noAnswers, user.percent]);
    })


    var docDefinition = {
        content: [
        {text: "Curso: "+ courseName, style: 'header'},
        {text: "nombre de quiz: "+quizName, style: ['subheader', 'anotherStyle']},
        {text: "fecha: "+formatDate(new Date(Date.now())), style: ['subheader', 'anotherStyle']},
        ,
            {
                style: 'tableExample',
                table: {
                    widths: ['auto','*', 'auto', 'auto', 'auto'],
                    body: data
                }
            },
        ],	
        pageSize: 'letter',
        styles: {
            header: {
                fontSize: 22,
                bold: true,
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
                fillColor: 'gray'
            },
            tableExample: {                
                margin: [0, 5, 0, 15]
            },
            anotherStyle: {
                italics: true,
            }
        },
    };

    return docDefinition;
}

function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();      
    return str.replace( /(<([^>]+)>)/ig, '');
}

function buildArrayPdfForReviewStudent(fullName, date, qualification, correctAnswers, noAnswers, questions){
    var data = [];

    
    
    // users.map(user =>{
    //     data.push([user.fullname, user.correctAnswers, user.incorrectAnswers, user.noAnswers, user.percent]);
    // })

    questions.map((question, index) => {

        var questionStyle = "badQuestion";
        if(question.exam_answers.choice == question.exam_answers.correct_choice){
            questionStyle = "correctQuestion";
        }
        data.push([{ text: (index+1)+".", style: questionStyle }]);
        data.push([{ text: removeTags(question.questiontext), style: "normalQuestion" }]);

        question.choices.map((choice)=>{
            var choiceStyle = "normalChoice";            
            if(question.exam_answers.choice == choice.id){
                choiceStyle = "badChoice";
            }
            if(choice.id == question.exam_answers.correct_choice){
                choiceStyle = "correctChoice";
            }
            data.push([{ text: removeTags(choice.answer), style: choiceStyle}]);
        })
    })
        
    var docDefinition = {
        content: [
        {text: fullName, style: 'header'},
        {text: "Fecha del examen: "+formatDate(new Date(date*1000)), style: ['subheader', 'anotherStyle']},
        {text: "Calificacion: "+qualification+"%", style: ['subheader', 'anotherStyle']},
        {text: "Preguntas correctas: "+correctAnswers, style: ['subheader', 'anotherStyle']},
        {text: "Preguntas no respondidas: "+noAnswers, style: ['subheader', 'anotherStyle']},
        
        //{text: "fecha: "+formatDate(new Date(Date.now())), style: ['subheader', 'anotherStyle']},
        data
        
        ],	
        pageSize: 'letter',
        styles: {
            header: {
                fontSize: 22,
                bold: true,
            },
            normalQuestion: {
                color: 'black',
                bold: true,
            },
            correctQuestion: {
                color: 'green',
                bold: true,
                fontSize: 15,
            },
            badQuestion: {
                color: 'red',
                bold: true,
                fontSize: 15,
            },
            normalChoice: {
                color: 'black'
            },
            badChoice: {
                color: 'red'
            },
            correctChoice: {
                color: 'green'
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
                fillColor: 'gray'
            },
            tableExample: {                
                margin: [0, 5, 0, 15]
            },
            anotherStyle: {
                italics: true,
            }
        },
    };

    return docDefinition;
}

module.exports = {
    buildArrayPdfForReviewTeacher,
    buildArrayPdfForReviewStudent
}