import React, {Component} from 'react'
import { Table, Form, Button,Container,Badge,Row,Col,Jumbotron, Spinner } from 'react-bootstrap';
import {
	BrowserRouter as Router,
	Redirect
} from 'react-router-dom'

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';

import { FaTimes,FaCheck,FaArrowLeft } from 'react-icons/fa';

import { IconContext } from "react-icons";



import { getExamAnswerByUser } from '../../../services/Quiz'
import RowStudent from './components/RowStudent';

export default class Review extends Component {

	constructor(...props){
		super(...props)
		this.state = { 
			redirectRoute: false,
			isLoading: false,
            questions: [],
			quizName: "",
			courseName:"",
            percent: "",
			session: "",
			correctAnswers: 0,
			noAnswers:0 ,
			total_items: 0
		}	
	}

    
    
	componentDidMount(){		
		this.setState({ isLoading: true})
		getExamAnswerByUser(localStorage.getItem('rev_quizId'),localStorage.getItem('rev_sessionId'))
        .then((res) => {
			if(res.status){
				//if(res.messa)
				switch(res.status){
					case 404:
						this.setState({ isLoading: false})
						break;
					case 401:
						this.props.logoutCallback();
						//this.setState({ redirectRoute: true })					
						break;
				}
			}else{
				this.setState({ isLoading: false, courseName: localStorage.getItem('courseName'),quizName: localStorage.getItem('rev_name'),percent: 
					res.percent,session: res.session, correctAnswers: res.correctAnswers, noAnswers:res.noAnswers, total_items: res.total_items  })
				this.setState({
					questions: this.state.questions.concat(res.questions)
				})
			}			
		})
		.catch(function(error) {
			this.setState({ isLoading: false})
			console.log('Hubo un problema con la petición Fetch:' + error.message);
		});
	}

	render(){	
		const {isLoading, redirectRoute, questions, courseName,quizName, percent, session, correctAnswers, noAnswers,total_items } = this.state
		var listQuestions = questions.map((question, index )=>{
			
			return <RowStudent key={question.id} question={question} index={index} />	
		})			


		if(redirectRoute){
			return(
				<Redirect to="/student-my-quizzes" />
			)
		}
		else{
			return(
				isLoading===true
					?<div style={{
						position: 'absolute', left: '50%', top: '50%',
						transform: 'translate(-50%, -50%)'
					}}>
						<Spinner animation="border" variant="info" />
					</div>
					:<Container >
						<Row className="justify-content-md-center">
							<Col>
								<h1>Curso: {courseName}</h1>
								<h3>Nombre de examen: {quizName}</h3>
								<h3>Estudiante: {localStorage.getItem("fullname")}</h3>
								<Button variant="outline-info" size="lg" onClick={()=> this.setState({redirectRoute: true})}>
									<IconContext.Provider value={{ color: "info" }}>
										<FaArrowLeft   /> 
									</IconContext.Provider>										
								</Button>
							</Col>
							
                        </Row>
						<Jumbotron>
						<Row className="justify-content-center">
							<Col>
								<Table  >
									<tbody>
										<tr>
											<td>Fecha del examen</td>
											<td> 
												<Moment format="HH:mm:ss MM-DD-YYYY" unix>{session.created_at}</Moment>
											</td>
										</tr>
										<tr><td>Calificacion</td><td>{percent+" %"}</td></tr>
										<tr><td>Preguntas correctas</td><td>{correctAnswers+" de "+total_items}</td></tr>
										<tr><td>Preguntas no respondidas</td><td>{noAnswers}</td></tr>
									</tbody>
								</Table>
							</Col>
							

						</Row>
						<Row>
							<Col>
								<Table  >
									<tbody>
										{listQuestions}
									</tbody>
								</Table>
							</Col>
						</Row>

                        </Jumbotron>
												
                    </Container>				
			)
		}
	}
}