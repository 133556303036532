import { Button} from 'react-bootstrap';

export const RowTeacher = ({user, goReview}) => {
    return (
        <tr >
            <td> 
                {user.fullname}
            </td>
            <td style={{textAlign:"center"}}> 
                {user.correctAnswers}
            </td>
            <td style={{textAlign:"center"}}> 
                {user.incorrectAnswers}
            </td>
            <td style={{textAlign:"center"}}> 
                {user.noAnswers}
            </td>
            <td style={{textAlign:"right"}}> 
                {user.percent}
            </td>
            <td > 
                <Button variant="info" type="button" onClick={()=>goReview(user)}  >
                    ver Detalles
                </Button>
            </td>
        </tr>
    );
}
export default RowTeacher;