import React, {Component} from 'react'
import { Table, Form, Button,Container, Spinner } from 'react-bootstrap';
import {
	BrowserRouter as Router,
	Redirect
} from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa';
import { IconContext } from "react-icons";

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';

import { getQuizzes, getMyQuizzesByCourse, getAllExamAnswerBySession } from '../../../services/Quiz'
import QuizRow from './components/RowQuiz';
import CustomPagination from '../../../components/CustomPagination';

export default class Student extends Component {

	

	constructor(...props){
		super(...props)
		this.state = { 
			redirectRoute: 0,
			isLoading: false,
            quizzes: [],
			page: 1,
			pages: 0,
			courseName: "",
			isTeacher: true,
			isStudent: true,
			users:"" 
		}	
		this.goReviewTeacher = this.goReviewTeacher.bind(this)
		//this.getParticipants = this.getParticipants.bind(this)
		//this.getAllExamAnswer = this.getAllExamAnswer.bind(this)	
	}
	
    
	componentDidMount(){	
		this.getQuizzes( 1)
	}

	getQuizzes = (page) => {
		this.setState({ isLoading: true})
		getMyQuizzesByCourse(localStorage.getItem('courseId') , page)
        .then((res) => {
			if(res.status){
				switch(res.status){
					case 404:
						this.setState({ isLoading: false})
						break;
					case 401:
						this.props.logoutCallback();
						break;
				}
			}else{
				
				this.setState({ isLoading: false, courseName: localStorage.getItem('courseName'), isTeacher: JSON.parse(localStorage.getItem('isTeacher')), 
				isStudent: JSON.parse(localStorage.getItem('isStudent')), pages: res.pages})
				// this.setState({
				// 	quizzes: this.state.quizzes.concat(res.quizzes)
				// })
				this.setState({
					quizzes: res.quizzes
				})
			}			
		})
		.catch(function(error) {
			this.setState({ isLoading: false});
			console.log('Hubo un problema con la petición Fetch:' + error.message);
		});
	}

	goReviewTeacher(quiz,e){		
		localStorage.removeItem("teach_sessionId");
		localStorage.setItem("teach_sessionId", quiz.sessionId);
		localStorage.removeItem("teach_quizId");
		localStorage.setItem("teach_quizId", quiz.quizId);
		localStorage.removeItem("teach_quizName");
		localStorage.setItem("teach_quizName", quiz.name);
		localStorage.removeItem("teach_isStudent");
		localStorage.setItem("teach_isStudent", this.state.isStudent);
		this.setState({redirectRoute: 2})			
		//	this.getAllExamAnswer(users.substring( 0, users.length-1), quiz.sessionId)
	}

	

	goReview(quiz, e){
		console.log("quiz sdfsd")
		console.log(quiz)
		localStorage.removeItem("rev_quizId");
		localStorage.setItem("rev_quizId",quiz.quizId);
		localStorage.removeItem("rev_sessionId");
		localStorage.setItem("rev_sessionId",quiz.sessionId);
		localStorage.removeItem("rev_name");
		localStorage.setItem("rev_name",quiz.name);
		this.setState({redirectRoute: 1})
	}
	
	render(){	
		const {isLoading, redirectRoute, quizzes, courseName, isTeacher, isStudent, pages} = this.state
		var listQuizzes = quizzes.map(quiz =>{
			return (
				<QuizRow key={quiz.sessionId} quiz={quiz} goReview={this.goReview.bind(this, quiz)} goReviewTeacher={this.goReviewTeacher.bind(this,quiz)} isTeacher={isTeacher} isStudent={isStudent} />
            )	
		})			

		switch(redirectRoute){
			case 0:
				return(
					<Container>
						<h1 className="header">Curso: {courseName}</h1>
						<Button variant="outline-info" size="lg" onClick={()=> this.setState({redirectRoute: 3})}>
							<IconContext.Provider value={{ color: "info" }}>
								<FaArrowLeft   /> 
							</IconContext.Provider>										
						</Button>
						<Table responsive striped bordered hover>
							<thead>
								<tr>
									<th>Nombre de examen</th>
									<th>Cod. de sesion</th>
									<th>Estado</th>
									<th>Fecha</th>										
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{listQuizzes}
							</tbody>
						</Table>	
						<CustomPagination pages={pages} getPage={(page)=> this.getQuizzes(page)} />
						{
							isLoading===true
							?<div style={{
								position: 'absolute',
								left: 0,
								top: 0, 
								width: '100%',
								height: '100%',						
							}}>
								<Spinner animation="border" variant="info" style={{position: 'relative', left: '50%', top: '50%'}} />
							</div>
							:<></>
						}						
					</Container>
				)
			case 1:
				return(
					<Redirect to="/review" />
				)
			case 2:
				return(
					<Redirect to="/review-teacher" />
				)
			case 3:
				return(
					<Redirect to="/my-courses" />
					)
		}

	}
}