import React, {useState, useEffect} from 'react'
import { Table, Button, Container, Row, Col, Spinner, Card, Pagination } from 'react-bootstrap';
import {
	Redirect,
} from 'react-router-dom'
import { getQuiz, getQuizzesByCourse } from '../../../services/Quiz'

import {FaCheck} from 'react-icons/fa';

import {IconContext} from "react-icons";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';
import { saveAs } from 'file-saver'
import {useFetchAndLoad} from '../../../hooks';

export default function ListQuizzes(props) {

	const [redirectRoute, setRedirectRoute] = useState(0);
	//const [isLoading, setLoading] = useState(true);
	const [isLoading2, setLoading2] = useState(false);
  const [quizzes, setQuizzes] = useState([]);

	const [commerce, setCommerce] = useState("");
	const [subsidiary, setSubsidiary] = useState("");
	const [product_prices, setProduct_prices] = useState([]);
	const [product, setProduct] = useState("");
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(0);
	const [btn_disabled, setBtn_disabled] = useState(false);
	const [isShowAlert, setShowAlert] = useState(false);
	const [alertObj, setAlertObj] = useState({title: "",message:"", typeError:0});
	const { loading, callEndpoint } = useFetchAndLoad();

	useEffect(() => { 
		/*var product = JSON.parse(localStorage.getItem("product"));
		var commerce = JSON.parse(localStorage.getItem("commerce"));
		var subsidiary = JSON.parse(localStorage.getItem("subsidiary"));
		setCommerce(commerce)
		setProduct(product)
		setSubsidiary(subsidiary)*/
	},[])
	
  	useEffect(() => {		
    	callEndpoint(getQuizzesByCourse(localStorage.getItem('courseId')))
        .then((res) => {
          setQuizzes(res.quizzes)		
		})
		.catch(function(error) {
      
			//this.setState({ isLoading: false})
			console.log('Hubo un problema con la petición Fetch:' + error.message);
		});
		
	}, [page]);

	

	const getQuiz2 = (quizId) => {
    console.log("quizId")
    console.log(quizId)
    getQuiz(quizId)
    .then((res) => {
      console.log("res")  
      console.log(res)  
      createFile(res.questions)
      //setQuizzes(res.quizzes)
        //setLoading(false)
          //console.log(res.quizzes)		
		})
		.catch(function(error) {
      //setLoading(false)
			//this.setState({ isLoading: false})
			console.log('Hubo un problema con la petición Fetch:' + error.message);
		});
  }

  const createFile = (questions) => {
    
    var dd = "";
    for(var i=0;i<questions.length; i++){
      dd += "#"+questions[i].name+"\n";
      for(var j=0; j < questions[i].answer.length; j++){
         dd+=questions[i].answer[j].answer+"\n";
      }
    }
    console.log(dd);
    const blob = new Blob([ dd ], { type: 'text/plain;charset=utf-8' });
    saveAs( blob, 'file.md' );
  }
	
	let active = page;
	let items = [];
	for (let number = 1; number <= pages; number++) {
		if(pages!==1){
			items.push(
				<Pagination.Item key={number} active={number === active} onClick={()=>{ }}>
				{number}
				</Pagination.Item>,
			);
		}
	}
	const paginationBasic = (
		<div>
			<Pagination>{items}</Pagination>
		</div>
		);



  var listQuizzes = quizzes.map(quiz =>{
  
    
    return (
        <tr key={quiz.id}>          
          <td>{quiz.name}</td>
          <td>{
            <Moment format="HH:mm:ss DD-MM-YYYY" unix>
							{quiz.timecreated}
					  </Moment>}
          </td>
          <td>
            <Button variant="secondary" type="button" title={"Agregar precio"} onClick={()=>{getQuiz2(quiz.id)}}  disabled={btn_disabled}>
              convertir a md
            </Button>
          </td>						
        </tr>
        )	
  })	 
		
	
	switch(redirectRoute){
		case 1:
			return(
				<Redirect to="/my-courses" />
			)
		case 0:
			return(
			<Container >
				<br/>
				<Row className="mb-3">
					<Col xs={12} md={6} lg={7} xl={8} xxl={8}>
						<h1>Lista de quizzes</h1>
						<Button variant="outline-secondary" type="button" onClick={()=>{setRedirectRoute(1)}} >
							Atras
						</Button>
						
					</Col>
					
				</Row>
				{
					loading===true
					?<Row className="justify-content-center" >
						<Spinner animation="border" variant="info" />
					</Row>
					:<Row className="mb-3">
						<Col>
							{paginationBasic}
							<Table striped bordered hover variant="info">
								<thead>
									<tr>
										<th>Nombre</th>
										<th>Creación</th>
										<th></th>											
									</tr>
								</thead>
								<tbody>
                  					{listQuizzes}
								</tbody>
							</Table>
						</Col>
					</Row>
				}
				{
					isLoading2===true
					?<div style={{
						position: 'absolute', left: '50%', top: '50%',
						transform: 'translate(-50%, -50%)'
					}}>
						<Spinner animation="border" variant="info" />
					</div>
					:<></>
				}
				
				
			</Container>	            				
		)
		default:
	}
}