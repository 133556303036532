import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';


// Example items, to simulate fetching from another resources.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38];

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div>
            <h3>Item #{item}</h3>
          </div>
        ))}
    </>
  );
}

function PaginatedItems({ pages, getPage}) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  //const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  //const endOffset = itemOffset + itemsPerPage;
  //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //const currentItems = items.slice(itemOffset, endOffset);
  //const pageCount = Math.ceil(size / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    //const newOffset = (event.selected * itemsPerPage) % size;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    //setItemOffset(newOffset);
    getPage(event.selected+1)
  };

  return (
    <>
        {/* <Items currentItems={currentItems} /> */}
        <ReactPaginate
            nextLabel="sig >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pages}
            previousLabel="< ant"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
        />
    </>
  );
}


export default PaginatedItems;