import { Button} from 'react-bootstrap';
import { changeRoleToSpanish } from '../../../../utilities/helper'


export const RowMyCourses = ({course, goQuizStudent}) => {
    var roles="";
    var isTeacher = false;
    var isStudent = false;			
    
    var myRoles = Array();
    for(var i=0;i<course.userroles.length;i++){
        roles += changeRoleToSpanish(course.userroles[i].shortname) +", ";
        myRoles.push(course.userroles[i].roleid);
        if(course.userroles[i].roleid==3 || course.userroles[i].roleid==4) {
            isTeacher = true
        }
        if(course.userroles[i].roleid==5){
            isStudent = true;
        }
    }    
    return (
        <tr >
            <td>{course.fullname}</td>
            <td>{roles.substring( 0, roles.length-2)}</td>
            <td>
                {/* {
                    isTeacher === true
                    ?<>
                        <form action="http://localhost/nuevo/reveal.js/maestro.php" target="_blank" method="post">
                        <input type="hidden" name="redbiblica" value="si" />
                        <input type="hidden" name="user" value={localStorage.getItem('fullname')} />
                        <input type="hidden" name="course" value={course.id} />					
                        <input type="hidden"  name="token" value={localStorage.getItem('token')} />
                        <input type="submit" className="btn btn-info" value="Presentar como maestro" />
                        </form>
                        <br />
                    </>
                    :<>
                        <form action="http://localhost/nuevo/reveal.js/estudiante.php" target="_blank" method="post">
                        <input type="hidden" name="redbiblica" value="si" />
                        <input type="hidden" name="user" value={localStorage.getItem('fullname')} />
                        <input type="hidden" name="course" value={course.id} />					
                        <input type="hidden"  name="token" value={localStorage.getItem('token')} />
                        <input type="submit" className="btn btn-info" value="Ir a presentación" />
                        </form>
                        <br />
                    </>
                } */}
                
                <Button variant="info" type="button" onClick={() => goQuizStudent(course, isTeacher,isStudent)}>
                    Mis examenes
                </Button>
                <br/><br/>
                {/* <Button variant="info" type="button" onClick={()=> this.goListQuizzes(course)}>
                    Lista quizzes
                </Button>						  */}
            </td>
        </tr>
        
    );
}
export default RowMyCourses;