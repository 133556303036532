import { Button} from 'react-bootstrap';
import { changeSessionStatusToSpanish } from '../../../../utilities/helper'
import Moment from 'react-moment';

export const RowQuiz = ({quiz, goReview, goReviewTeacher, isStudent, isTeacher}) => {
    return (
        <tr >
            <td>{quiz.name}</td>
            <td>{quiz.sessionId}</td>
            <td>{changeSessionStatusToSpanish(quiz.exam_status)}</td>					
            <td><Moment format="HH:mm:ss MM-DD-YYYY" unix>{quiz.created_at}</Moment></td>					
            <td>
                {
                    isStudent===true
                    ?<>
                        <Button variant="info" type="button" onClick={()=>goReview(quiz)}>
                            Revision
                        </Button>&nbsp;
                    </>	
                    :<></>
                }
                
                {
                    isTeacher === true
                    ?<Button variant="info" type="button" onClick={()=>goReviewTeacher(quiz)}>
                        Revisar como maestro
                        </Button>
                    :<></>							
                }
            </td>
        </tr>
    );
}
export default RowQuiz;