import React, {useState, useEffect} from 'react'
import {getCourseMds} from '../../../services/Course'
import { Table, Button, Container, Row, Col, Spinner, Card} from 'react-bootstrap';
import {
	Redirect,
} from 'react-router-dom'
import { FaUpload } from 'react-icons/fa';

import {newToken} from '../../../utilities/helper'
import RowCourse_md from './components/RowCourse_md';

import {useFetchAndLoad} from '../../../hooks';

import 'moment-timezone';
import 'moment/locale/es';

export default function Course_mds(props){

	const [course, setCourse] = useState("");
	const [course_mds, setCourse_mds] = useState([]);
    const [redirectRoute, setRedirectRoute] = useState(0);
	const { loading, callEndpoint } = useFetchAndLoad();
	

	useEffect(() => { 
		var course = JSON.parse(localStorage.getItem("course"));	    
		setCourse(course);
		callEndpoint(getCourseMds(course.id), props)
		.then((res) => {
			setCourse_mds(res.course_mds)
			newToken(res.token);	
		})
		.catch((error) => {
			console.log('Hubo un problema con la petición Fetch:' + error.message);
		});
		
	},[])

	var listCourses = course_mds.map(course_md2 =>{
		return (
			<RowCourse_md key={course_md2.id} course_md={course_md2}/>
		)	
	})			
		
	
	switch(redirectRoute){
		case 2:
			return(
				<Redirect to="/courses" />
			)
		case 1:
			return(
				<Redirect to="/uploadMd" />
			)
		case 0:
			return(
			<Container>
				<br/>
				<Row className="mb-3">
					<Col xs={12} md={6} lg={7} xl={8} xxl={8}>
						<h1>Lista de Mds</h1>	
						<Button variant="outline-secondary" type="button" onClick={()=>{setRedirectRoute(2)}} >
								Atras
						</Button>
						{' '}
						<Button variant="outline-secondary" type="button" onClick={()=>{setRedirectRoute(1)}} >
							<FaUpload/>
						</Button>					
					</Col>
					<Col xs={12} md={6} lg={5} xl={4} xxl={4}>
						<Card border="info" style={{ width: '18rem' }}>
							<Card.Body>
								<Card.Text>
									Curso :<br/><strong>{course.fullname}</strong>
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>					
				</Row>
				{
					loading===true
					?<Row className="justify-content-center" >
						<Spinner animation="border" variant="info" />
					</Row>
					:<Row className="mb-3">
						<Col>
							<Table striped bordered hover variant="info">
								<thead>
									<tr>
										<th>Nombre de archivo</th>
										<th>Creación</th>										
									</tr>
								</thead>
								<tbody>
									{listCourses}
								</tbody>
							</Table>
						</Col>
					</Row>
				}
			</Container>	            				
		)
		default:
	}
}