import React, {useState, useEffect} from 'react'
import {getCourses} from '../../../services/Course'
import { Table, Container, Row, Col, Spinner} from 'react-bootstrap';
import {
	Redirect,
} from 'react-router-dom'
import RowCourse from './components/RowCourse';
import CustomPagination from '../../../components/CustomPagination';
import {useFetchAndLoad} from '../../../hooks';

export default function Courses(props){


	const [courses, setCourses] = useState([]);
    const [redirectRoute, setRedirectRoute] = useState(0);
	const { loading, callEndpoint } = useFetchAndLoad();
	const [page,setPage] = useState(1);
	const [pages,setPages] = useState(0);
	

	useEffect(() => { 
		callEndpoint(getCourses(page), props)
		.then((res) => {
			setCourses(res.courses)		
			setPages(res.pages)
		})
		.catch((error) => {
			console.log('Hubo un problema con la petición Fetch:' + error.message);
		});		
	},[page])

	const goUploadMd = (course) =>{
		localStorage.removeItem("course");
		localStorage.setItem("course",JSON.stringify(course));
		setRedirectRoute(2)
	}

	var listCourses = courses.map(course =>{
		return (
			<RowCourse key={course.id} course={course} goUploadMdC = { goUploadMd}/>
		)	
	})			
		
	
	switch(redirectRoute){
		case 2:
			return(
				<Redirect to="/course_mds" />
			)
		case 1:
			return(
				<Redirect to="/login" />
			)
		case 0:
			return(
			<Container>
				<br/>
				<Row className="mb-3">
					<Col xs={12} md={6} lg={7} xl={8} xxl={8}>
						<h1>Lista de cursos</h1>
					</Col>
					
				</Row>
				<Row className="mb-3">
					<Col>
						<Table striped bordered hover variant="info">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Creación</th>										
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{listCourses}
							</tbody>
						</Table>
						<CustomPagination pages={pages} getPage={(page)=> setPage(page)} />
					</Col>
				</Row>
				{
					loading===true
					?<div style={{
						position: 'absolute',
						left: 0,
						top: 0, 
						width: '100%',
						height: '100%',						
					}}>
						<Spinner animation="border" variant="info" style={{position: 'relative', left: '50%', top: '50%'}} />
					</div>
					:<></>					
				}
			</Container>	            				
		)
		default:
	}
}