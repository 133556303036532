import React, {useState, useEffect} from 'react'
import {uploadMd } from '../../../services/Course'
import {Form, Button, Container, Row, Col, Spinner, Card} from 'react-bootstrap';
import {
	Redirect
} from 'react-router-dom'
//import {AlertDismissible} from '../hooks/messages/CustomAlert';
//import {statusToObject} from '../../../helpers/Methods'
//import {feedBackFile} from '../../../helpers/regex'
//var sd = require()
import {useFetchAndLoad} from '../../../hooks';

export default function UploadMd(props) {

	const [course, setCourse] = useState("");
	const [selectedFile, setSelectFile] = useState(undefined);
	const [redirectRoute, setRedirectRoute] = useState(false);
	const [btn_disabled, setBtn_disabled] = useState(false);
	const [validated, setValidated] = useState(true);

	const { loading, callEndpoint } = useFetchAndLoad();

	useEffect(() => { 
		var course = JSON.parse(localStorage.getItem("course"));
		setCourse(course);
	},[])
	
	

	const save = () =>{
		setBtn_disabled(true)
		var formdata = new FormData();
        formdata.append("file", selectedFile, selectedFile.name);
		callEndpoint(uploadMd(course.id, formdata), props)
		.then((res) => {
			setRedirectRoute(true);
		})
		.catch((error) => {
			setBtn_disabled(false)
			alert(error.message)
		});
	}

    const onFileChange = event => {
        setSelectFile(event.target.files[0]); 
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity()) {
			save()
		}
	}
	
	if(redirectRoute){
		return(
			<Redirect to="/course_mds" />
		)
	}
	else{
		return(
			<Container>
				<br/>
				<Row className="mb-3">
					<Col xs={12} md={6} lg={7} xl={8} xxl={8}>
						<h1>Agregar MD</h1>						
					</Col>
					<Col xs={12} md={6} lg={5} xl={4} xxl={4}>
						<Card border="info" style={{ width: '18rem' }}>
							<Card.Body>
								<Card.Text>
									Curso :<br/><strong>{course.fullname}</strong>
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>					
				</Row>
				<Row className="mb-3">
					<Col xs={12} md={6} lg={7} xl={8} xxl={8}>
						<br/>
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<Form.Group className="mb-3" controlId="formFile">
								<Form.Label>Seleccione un md</Form.Label>
								<Form.Control type="file" onChange={onFileChange} accept=".md" required/>
								<Form.Control.Feedback type="invalid">{"md requerido"}</Form.Control.Feedback>                      
								<Form.Control.Feedback>✔</Form.Control.Feedback>
							</Form.Group>
							<Button variant="info" type="submit" disabled={btn_disabled} >
								Agregar
							</Button>{" "}
							<Button variant="secondary" type="button" onClick={()=>{setRedirectRoute(true)}}>
								Cancelar
							</Button>
						</Form>
					</Col>
				</Row>
				{
					loading===true
					?<div style={{
						position: 'absolute', left: '50%', top: '50%',
						transform: 'translate(-50%, -50%)'
					}}>
						<Spinner animation="border" variant="info" />
					</div>
					:<></>
				}
			</Container>				
		)
	}
}