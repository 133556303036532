import { useEffect, useState } from 'react';

const useFetchAndLoad = () => {
    const [loading, setLoading] = useState(false);
  
    const callEndpoint = async (call, props) => {
        setLoading(true);
        let result;
        try {
            result = await call;
        }catch(err){
            setLoading(false);
            throw err;
        }
        setLoading(false);
        return isResponseOk(result, props);
    }  

    const isResponseOk = (response, props) => {
        if (!response.ok){
            console.log("response")
            console.log(response)
            switch (response.status){
                case 401:
                    localStorage.clear(); 
                    props.logoutCallback();
                    throw new Error('usuario no autorizado');
                case 404:
                    throw new Error('No existe datos');
                case 403:
                    throw new Error('No tienes permiso para para usar este recurso');
                case 400:
                    //var res = await response.json()
                    throw new Error("Validacion de datos fallida");
            }
        }else{
            return response.json();
        }
    }

  //const cancelEndpoint = () => {
  //  setLoading(false);
    //controller && controller.abort();
  //};

  //useEffect(() => {
  //  return () => {
  //    cancelEndpoint();
  //  };
  //}, []);

  return { loading, callEndpoint };
};

export default useFetchAndLoad;
