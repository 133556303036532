import { url_server } from '../data/global'

const login = (username, password) =>
	fetch(url_server+"login/tokenJWT.php",
		{
			method: 'POST',
			body: new URLSearchParams(
				{
					"username": username,
					"password": password,
					"service": "moodle_mobile_app"
				}),
			headers: { 
				'Content-Type': 'application/x-www-form-urlencoded'
			}				
		}
	)
	.then((response) => {
		return response.json()
	})
	
		
export {
	login
}