const changeSessionStatusToSpanish = (type) => {
    switch(type){
        case "Disabled":
            return "Desabilitado"       
        case "Enabled":
            return "Habilitado"
        case "Done":
            return "Terminado"
    }
}

const changeRoleToSpanish = (type) => {
    switch(type){
        case "editingteacher":
            return "Profesor"       
        case "student":
            return "Estudiante"
        case "teacher":
            return "Profesor"
    }
}

const newToken = (token) => {
    if(token){
        localStorage.removeItem("token");
        localStorage.setItem("token",token);
    }else{
        console.log("fail token")
        console.log(token)
    }   
}


export {
    changeSessionStatusToSpanish,
    changeRoleToSpanish,
    newToken
}