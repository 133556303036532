import React, {Component} from 'react'
import {login} from '../../services/Auth'
import { Button,Form,Container,Jumbotron,Spinner } from 'react-bootstrap';
import {
	BrowserRouter as Router,
	Route,
	Link,
	Redirect,
	withRouter,
	Switch
} from 'react-router-dom'

export default class Login extends Component {
	constructor(...props){
		super(...props)
		this.state = { 
			loginMessage: null,
			redirectRoute: false,
			message: "",
			btn_disabled: false,
			isLoading: false
			//authed: this.props.authed 
		}
		this.handleOnSubmit = this.handleOnSubmit.bind(this)
		this.setMessage = this.setMessage.bind(this)
		//this.resetPassword = this.resetPassword.bind(this)
		console.log("this.props");
		//console.log(this.props);
		//console.log(this.props.authed);
	}

	handleOnSubmit(e){
		this.setState({message: "" ,btn_disabled: true,isLoading: true})
		e.preventDefault()
		login(this.email.value,this.password.value)
        .then((res) => {
			if(res.token){
				console.log("existe");
				localStorage.removeItem("token");
				localStorage.setItem("token",res.token);
				localStorage.removeItem("tokenMoodle");
				localStorage.setItem("tokenMoodle",res.tokenMoodle);
				localStorage.removeItem("user");
				localStorage.setItem("user",res.user);
				localStorage.removeItem("fullname");
				localStorage.setItem("fullname",res.fullname);					
				this.props.loginCallback();
				this.setState({redirectRoute: true, isLoading: false})
			}else{
				this.setState({message: "usuario o contraseña incorrecta",btn_disabled: false, isLoading: false})
			}
		})
		.catch(function(error) {
			this.setState({message: "Hubo un problema intentalo de nuevo", btn_disabled: false});
		});
	}

	setMessage(err){
		return {loginMessage: err}
	}

	render(){
		const {isLoading, redirectRoute, message, btn_disabled } = this.state
		if(redirectRoute){
			return(
				<Redirect to="/subject" />
			)
		}
		else{		
			return(
				isLoading===true
				?<div style={{
					position: 'absolute', left: '50%', top: '50%',
					transform: 'translate(-50%, -50%)'
				}}>
					<Spinner animation="border" variant="info" />
				</div>
				:
				<Container className="p-3">
					<Jumbotron>
						<h1 className="header">Inicio de sesion</h1>
						<Form onSubmit={this.handleOnSubmit}>
							<Form.Group controlId="formBasicEmail">
								<Form.Label>Nombre de usuario</Form.Label>
								<Form.Control type="text" placeholder="Nombre de usuario" ref={email=>this.email=email} />
							</Form.Group>

							<Form.Group controlId="formBasicPassword">
								<Form.Label>Password</Form.Label>
								<Form.Control type="password" placeholder="Password" ref={password => this.password = password} />
							</Form.Group>
							<Button variant="primary" type="submit" disabled={btn_disabled}>
								Ingresar
							</Button>
							<Form.Group controlId="formBasicMessage">
								<Form.Label>{message}</Form.Label>
							</Form.Group>
						</Form>
						
					</Jumbotron>
					
				</Container>
				            
			)
		}
	}
}